import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import CustomSelectDropdown from "./../../CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../Pagination/Pagination.component";
import useAggregatorOnboardingStatus from "../../../custom-hooks/useAggregatorsOnboardingStatus";
import ButtonLoader, { Loader } from "./../../../UI/Loaders/Loaders";
import { AGGREGATOR_ONBOARDING_STATUS } from "./../../../helpers/aggregatorOnboardingStatus";
import { showModal } from "../../../redux/actions/modal";
import { getTokenFromLocalStorage } from "../../../helpers/localStorage";
import { BASE_URL } from "../../../appConstants";
import { appInsights } from "../../../config/appInsights";
import { getAllAggregators } from "../../../custom-hooks/useAllAggregators";
import CustomSearchDropDown from "../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";

export default function ApprovedRequests() {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [searchValue, setSearchValue] = useState("");

  const [query, setQuery] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);

  const dispatch = useDispatch();

  const { data, status, error } = useAggregatorOnboardingStatus({
    Page: pageNumber,
    PageSize: size,
    onboardingStatus: `onboardingStatus=6&onboardingStatus=8&onboardingStatus=9`,
    // SubsequentOnboardingStatus: `SubsequentOnboardingStatus=6&SubsequentOnboardingStatus=8&SubsequentOnboardingStatus=9`,
    StartDate: query?.startDate,
    EndDate: query?.endDate,
    customerId: Number(searchValue),
  });

  const handleDownload = (e) => {
    e.preventDefault();
    setDownloadLoading(true);
    resetTable();

    let token = getTokenFromLocalStorage();

    const onboardingStatus = `onboardingStatus=6&onboardingStatus=8&onboardingStatus=9`;
    // const SubsequentOnboardingStatus = `SubsequentOnboardingStatus=6&SubsequentOnboardingStatus=8&SubsequentOnboardingStatus=9`;

    let queryParams = `?${onboardingStatus}`;

    if (query.startDate && query.endDate) {
      queryParams = `${queryParams}&StartDate=${query.startDate}&EndDate=${query.endDate}`;
    }

    fetch(
      BASE_URL + `/account/aggregator/onboardingstaus/query/csv${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
        // body: JSON.stringify(data)
      }
    )
      .then((response) => {
        setDownloadLoading(false);
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${
                query.startDate && query.endDate
                  ? `Approved onboarding requests from ${query.startDate} to ${query.endDate}.csv`
                  : `ApprovedOnboardingRequests.csv`
              }`
            );
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          this.props.history.push("/login");
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch(() => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ApprovedRequests.component.jsx (Download)",
          },
        });

        setDownloadLoading(false);
      });
  };

  const handleSearchByDate = (e) => {
    e.preventDefault();
    resetTable();

    const diffTime =
      new Date(filterParams.endDate) - new Date(filterParams.startDate);

    if (
      diffTime < 0 ||
      (filterParams.startDate && !filterParams.endDate) ||
      (!filterParams.startDate && filterParams.endDate)
    ) {
      dispatch(
        showModal({
          modalIdentifier: "audittrail",
          dataRecord: "",
          action: "customrange",
          type: "alert",
        })
      );
    } else {
      setQuery(filterParams);
    }
  };

  function getOptions(query) {
    return new Promise((resolve, reject) => {
      getAllAggregators({ SearchValue: query })
        .then((data) => {
          resolve(
            data.map(({ biz_name, customer_id }) => ({
              value: customer_id,
              label: biz_name,
            }))
          );
        })
        .catch(reject);
    });
  }

  const handleAggregatorChange = (record) => {
    if (record?.value) {
      setSearchValue(record?.value);
    } else {
      setSearchValue(null);
    }
    setPageNumber(1);
    setSize(1000);
  };

  const resetTable = () => {
    setPageNumber(1);
  };

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Approved Onboarding
            Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading onboarding requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              approved onboarding requests, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no approved
              requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Business Name</th>
                  <th>Business Email</th>
                  <th>Contact Person</th>
                  <th>Contact Email</th>
                  <th>Business Type</th>
                  <th>Source</th>
                  <th>Request Date</th>
                  <th>Initial Status</th>
                  <th>Subsequent Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data.map((request, _id) => {
                    const {
                      customerId,
                      businessName,
                      modifiedDate,
                      contactEmail,
                      contactPerson,
                      onboardingStatus,
                      subsequentOnboardingStatus,
                      personalEmail,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                        <td>{businessName}</td>
                        <td>{contactEmail}</td>
                        <td>{contactPerson}</td>
                        <td>{personalEmail}</td>
                        <td></td>
                        <td></td>
                        <td>{modifiedDate?.substring(0, 10)}</td>
                        <td>
                          {onboardingStatus &&
                          AGGREGATOR_ONBOARDING_STATUS[onboardingStatus]
                            ? AGGREGATOR_ONBOARDING_STATUS[onboardingStatus]
                            : onboardingStatus}
                        </td>
                        <td>{subsequentOnboardingStatus}</td>
                        <td>
                          <Link
                            to={`/aggregator-onboarding/${customerId}?approved=true`}
                            className="color-blue"
                          >
                            Review Request
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div>
        {status === "success" && (
          <span>
            You have{" "}
            <span className="font-weight-bold">
              {data?.totalCount} approved
            </span>{" "}
            onboarding requests
          </span>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-between mt-1">
        <div className="d-flex flex-wrap justify-content-start align-items-center">
          <div
            className="input-group mr-3"
            style={{
              maxWidth: "200px",
            }}
          >
            <CustomSearchDropDown
              label="Select Aggregator"
              loadOptions={getOptions}
              onChange={handleAggregatorChange}
              isShowLabel={false}
            />
          </div>
          <div style={{ width: "150px" }}>
            <CustomSelectDropdown
              onChange={(e) => {
                resetTable();
                setSize(e.target.value);
              }}
              value={size}
            >
              <option value={10000}>All</option>
              <option value={10}>10 Entries</option>
              <option value={20}>20 Entries</option>
              <option value={50}>50 Entries</option>
              <option value={100}>100 Entries</option>
            </CustomSelectDropdown>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <form onSubmit={handleSearchByDate}>
            <div
              className="d-flex flex-row flex-wrap justify-content-start"
              style={{ alignItems: "flex-end" }}
            >
              <div className="m-l-1 m-r-1 d-flex form-group">
                <span
                  style={{
                    display: "inline-block",
                    marginTop: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                >
                  Start:
                </span>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) =>
                    setFilterParams({
                      ...filterParams,
                      startDate: e.target.value,
                    })
                  }
                  value={filterParams.startDate}
                />
              </div>

              <div className="m-l-1 m-r-1 d-flex form-group">
                <span
                  style={{
                    display: "inline-block",
                    marginTop: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                >
                  End:
                </span>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) =>
                    setFilterParams({
                      ...filterParams,
                      endDate: e.target.value,
                    })
                  }
                  value={filterParams.endDate}
                />
              </div>
              <button
                className="btn advancly-btn btn-md mb-3"
                disabled={status === "loading"}
              >
                Search{status === "loading" && <ButtonLoader />}
              </button>
              <button
                className="btn advancly-btn btn-md ml-3 mb-3"
                onClick={handleDownload}
                type="button"
              >
                <i className="fas fa-download m-r-1" />
                Download{downloadLoading && <ButtonLoader />}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/*Datatable || Loader || Error Message*/}
      {RenderedComponent}
      <Paginator
        size={size}
        page={pageNumber}
        count={data?.totalCount}
        changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
      />
    </div>
  );
}
