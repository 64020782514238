import { useEffect } from "react";
import { IShareHolders } from "../../../DigitalBank/Interfaces";
import { appInsights } from "../../../../config/appInsights";
import Accordion from "../../../DigitalBank/Components/Accordion";
import CustomInputField from "../../../CustomHTMLElements/CustomInputField";

interface IShareholdersProps {
  data: IShareHolders[];
}

const Shareholders = ({ data: shareholders }: IShareholdersProps) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "ShareHolders.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="table-view speed-1x animated fadeInRight">
      {shareholders.map((_item, _id) => (
        <div className="mb-3" key={_id}>
          <Accordion
            title={`Share Holder (${_id + 1})`}
            subTitle="Click the - icon to see more information on the share holder"
          >
            <div className="row">
              <div className="col-md-5">
                <CustomInputField
                  label="Share holder type"
                  type="text"
                  name="shareholderType"
                  defaultValue={_item?.shareholderType!}
                  disabled
                />

                {_item?.shareholderType === "Individual" && (
                  <CustomInputField
                    label="Last Name"
                    type="text"
                    name="lastname"
                    defaultValue={_item?.lastname!}
                    disabled
                  />
                )}

                <CustomInputField
                  label="Currency"
                  type="text"
                  name="currency"
                  defaultValue={_item?.currency!}
                  disabled
                />
                <CustomInputField
                  label="Percentage of Shares"
                  type="text"
                  name="percentageShareholding"
                  defaultValue={_item?.percentageShareholding!}
                  disabled
                />

                {_item?.shareholderType === "Corporate" && (
                  <CustomInputField
                    label="Share Value"
                    type="text"
                    name="amount"
                    defaultValue={_item?.amount!}
                    disabled
                  />
                )}
              </div>
              <div className="col-md-5">
                <CustomInputField
                  label={
                    _item?.shareholderType === "Corporate"
                      ? "Name"
                      : "FirstName"
                  }
                  type="text"
                  name="firstName"
                  defaultValue={_item?.firstname!}
                  disabled
                />

                <CustomInputField
                  label="No of Shares"
                  type="text"
                  name="numberOfShares"
                  defaultValue={_item?.numberOfShares!}
                  disabled
                />
                <CustomInputField
                  label="Nationality"
                  type="text"
                  name="nationality"
                  defaultValue={_item?.nationality!}
                  disabled
                />

                {_item?.shareholderType === "Individual" && (
                  <CustomInputField
                    label="Share Value"
                    type="text"
                    name="amount"
                    defaultValue={_item?.amount!}
                    disabled
                  />
                )}
              </div>
            </div>
          </Accordion>
        </div>
      ))}

      {shareholders && shareholders.length < 1 && (
        <div className="w-100 d-flex align-items-center justify-content-center py-3">
          <h3 className="font-weight-bold">
            Customer is yet to submit Share Holder Information
          </h3>
        </div>
      )}
    </div>
  );
};

export default Shareholders;
