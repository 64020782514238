import { useEffect } from "react";

import { useSelector } from "react-redux";
import {
  boardOfDirectorsDocumentType,
  IBusinessOwnersInformation,
} from "../../../DigitalBank/Interfaces";
import useDocumentViewer from "../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { RootState } from "../../../../redux/store";
import { appInsights } from "../../../../config/appInsights";
import CustomInputField from "../../../CustomHTMLElements/CustomInputField";
import { DownloadUploadedDocument } from "../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import { documentApprovalStatusString } from "../../util";
import { auditRoleList, isAudit } from "../../../../helpers/roleTypes";

interface IBusinessOwnersInformationProps {
  data: IBusinessOwnersInformation;
  setActionData: React.Dispatch<React.SetStateAction<{}>>;
  funderId: number;
}

const BusinessOwnersInformation = ({
  data,
  setActionData,
  funderId,
}: IBusinessOwnersInformationProps) => {
  const { handleViewDocument } = useDocumentViewer();

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "BusinessOwnersInformation.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="table-view speed-1x animated fadeInRight">
      <div className="row">
        <div className="col-md-5">
          <CustomInputField
            label="First Name"
            type="text"
            name="firstName"
            defaultValue={data?.firstName}
            disabled
          />
          <CustomInputField
            label="Gender"
            type="text"
            name="gender"
            defaultValue={data?.gender}
            disabled
          />
          <CustomInputField
            label="Date of Birth"
            type="date"
            name="dateOfBirth"
            defaultValue={
              data?.dateOfBirth ? data?.dateOfBirth.split("T")[0] : ""
            }
            disabled
          />
          <CustomInputField
            label="Residential Address"
            type="text"
            name="address"
            defaultValue={data?.address!}
            disabled
          />
          <CustomInputField
            label="Identification"
            type="text"
            name="identityNumber"
            defaultValue={data?.identityNumber!}
            disabled
          />
          <CustomInputField
            label="Country"
            type="text"
            name="countryCode"
            defaultValue={data?.countryCode!}
            disabled
          />

          <div className="mb-5">
            <DownloadUploadedDocument
              name={"ID Upload"}
              pathname={data?.governmentIdFilePath}
              handleView={() =>
                handleViewDocument(false, data?.governmentIdFilePath)
              }
            />

            <div className="d-flex ml-5">
              <div className="d-flex">
                {data.governmentIdApprovalStatus ===
                  documentApprovalStatusString?.DECLINED && (
                  <button className="btn advancly-red-btn btn-md" disabled>
                    Declined
                  </button>
                )}
                {data.governmentIdApprovalStatus ===
                  documentApprovalStatusString?.APPROVED && (
                  <button className="btn advancly-green-btn btn-md" disabled>
                    Approved
                  </button>
                )}
              </div>

              {data.governmentIdApprovalStatus ===
                documentApprovalStatusString?.PENDING &&
                !isAudit([...loggedInUser?.roles], [...auditRoleList]) && (
                  <>
                    <button
                      type="button"
                      className="btn advancly-btn btn-md"
                      data-toggle="modal"
                      data-target="#funderPeopleDocumentsApprovalModal"
                      // disabled={handleDisableDocumentAction(loggedInUser)}
                      onClick={() =>
                        setActionData({
                          actionType: "approve",
                          data: {
                            data: {
                              id: data?.funderPersonId,
                              documentType: data?.govtIdentityType,
                              funderId,
                            },
                            documentType:
                              boardOfDirectorsDocumentType?.GOVERNMENT_ID,
                          },
                        })
                      }
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      className="btn btn advancly-off-2-btn btn-md ml-3"
                      data-toggle="modal"
                      data-target="#funderPeopleDocumentsApprovalModal"
                      // disabled={handleDisableDocumentAction(loggedInUser)}
                      onClick={() =>
                        setActionData({
                          actionType: "decline",
                          data: {
                            data: {
                              id: data?.funderPersonId,
                              documentType: data?.govtIdentityType,
                              funderId,
                            },
                            documentType:
                              boardOfDirectorsDocumentType?.GOVERNMENT_ID,
                          },
                        })
                      }
                    >
                      Decline
                    </button>
                  </>
                )}
            </div>
          </div>

          <div className="mb-5">
            <DownloadUploadedDocument
              name={"Proof of Address"}
              pathname={data?.proofOfAddressFilePath}
              handleView={() =>
                handleViewDocument(false, data?.proofOfAddressFilePath)
              }
            />

            <div className="d-flex ml-5">
              <div className="d-flex">
                {data.proofOfAddressApprovalStatus ===
                  documentApprovalStatusString?.DECLINED && (
                  <button className="btn advancly-red-btn btn-md" disabled>
                    Declined
                  </button>
                )}
                {data.proofOfAddressApprovalStatus ===
                  documentApprovalStatusString?.APPROVED && (
                  <button className="btn advancly-green-btn btn-md" disabled>
                    Approved
                  </button>
                )}
              </div>

              {data.proofOfAddressApprovalStatus ===
                documentApprovalStatusString?.PENDING &&
                !isAudit([...loggedInUser?.roles], [...auditRoleList]) && (
                  <>
                    <button
                      type="button"
                      className="btn advancly-btn btn-md"
                      data-toggle="modal"
                      data-target="#funderPeopleDocumentsApprovalModal"
                      // disabled={handleDisableDocumentAction(loggedInUser)}
                      onClick={() =>
                        setActionData({
                          actionType: "approve",
                          data: {
                            data: {
                              id: data?.funderPersonId,
                              documentType: data?.govtIdentityType,
                              funderId,
                            },
                            documentType:
                              boardOfDirectorsDocumentType?.PROOF_OF_ADDRESS,
                          },
                        })
                      }
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      className="btn btn advancly-off-2-btn btn-md ml-3"
                      data-toggle="modal"
                      data-target="#funderPeopleDocumentsApprovalModal"
                      // disabled={handleDisableDocumentAction(loggedInUser)}
                      onClick={() =>
                        setActionData({
                          actionType: "decline",
                          data: {
                            data: {
                              id: data?.funderPersonId,
                              documentType: data?.govtIdentityType,
                              funderId,
                            },
                            documentType:
                              boardOfDirectorsDocumentType?.PROOF_OF_ADDRESS,
                          },
                        })
                      }
                    >
                      Decline
                    </button>
                  </>
                )}
            </div>
          </div>
        </div>

        <div className="col-md-5">
          <CustomInputField
            label="Last Name"
            type="text"
            name="lastName"
            defaultValue={data?.lastName!}
            disabled
          />

          <CustomInputField
            label="Nationality"
            type="text"
            name="countryCode"
            defaultValue={data?.countryCode!}
            disabled
          />

          <CustomInputField
            label="BVN"
            type="text"
            name="bvn"
            defaultValue={data?.bvn!}
            disabled
          />

          <CustomInputField
            label="City"
            type="text"
            name="city"
            defaultValue={data?.city!}
            disabled
          />

          <CustomInputField
            label="State"
            type="text"
            name="state"
            defaultValue={data?.state!}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessOwnersInformation;
