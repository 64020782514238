import { useEffect, useState } from "react";
import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import Paginator from "../../../Pagination/Pagination.component";

import styles from "./auditTrail.module.scss";
import { appInsights } from "../../../../config/appInsights";
import { IAuditTrail } from "../../../DigitalBank/Interfaces";

interface IAuditTrailProps {
  data: IAuditTrail[];
}

const AuditTrail = ({ data: auditTrail }: IAuditTrailProps) => {
  const [page, setPage] = useState(1);
  const [size, setPageSize] = useState(10);

  useEffect(() => {
    appInsights.trackPageView({
      name: "AuditTrail.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="table-view speed-1x animated fadeInRight">
      <h1 className={styles.title}>Audit Trail</h1>
      <div>
        <>
          <MDBTable
            responsive
            minimal="true"
            striped={false}
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Action</th>
                <th>Comment</th>
                <th>Started By</th>
                <th>Status</th>
                <th>Completed By</th>
                <th>Date</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {auditTrail &&
                auditTrail
                  .slice((page - 1) * size, size * (page - 1) + size)
                  .map((data, _id: number) => {
                    const {
                      action,
                      actionDate,
                      comment,
                      completedBy,
                      startedBy,
                      status,
                    } = data;
                    return (
                      <tr key={_id}>
                        <td>{_id + 1}</td>
                        <td>{action}</td>
                        <td>{comment}</td>
                        <td>{startedBy}</td>
                        <td>{status}</td>
                        <td>{completedBy}</td>
                        <td>{actionDate}</td>
                      </tr>
                    );
                  })}
            </MDBTableBody>
          </MDBTable>
        </>

        <Paginator
          size={size}
          page={page}
          count={auditTrail && auditTrail.length - 1}
          changeCurrentPage={(pageNumber: number) => setPage(pageNumber)}
        />
      </div>
    </div>
  );
};

export default AuditTrail;
