import React from "react";
import { Table, Button, Dropdown, Space } from "antd";
import type { MenuProps } from "antd";
import {
  MoreOutlined,
  WalletOutlined,
  TransactionOutlined,
  LogoutOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { IWallet, TWalletLink } from "../../../../interfaces/funderInvestment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export interface IProps {
  wallets: IWallet[] | undefined;
  funderId: number;
  showWalletDetails: () => void;
  handleSetSelectedWalletDetails: (
    walletId: string,
    currency: string,
    availableBalance: number,
  ) => void;
  setShowWalletWithdrawal: (show: boolean) => void;
  setShowWalletTopUp: (show: boolean) => void;
  localWalletLink?: TWalletLink;
  foreignWalletLink?: TWalletLink;
}

const InvestorWalletTable = ({
  wallets,
  showWalletDetails,
  localWalletLink,
  foreignWalletLink,
  funderId,
  handleSetSelectedWalletDetails,
  setShowWalletWithdrawal,
  setShowWalletTopUp,
}: IProps) => {
  const history = useHistory();

  const handleWalletTransactions = (walletId: string, currency: string) => {
    if (localWalletLink && currency === "NGN") {
      history.push(localWalletLink.pathname as any, {
        id: funderId,
        savingsWalletId: walletId,
        currency: currency,
      });
    } else if (foreignWalletLink && currency !== "NGN") {
      history.push(foreignWalletLink.pathname as any, {
        id: funderId,
        savingsWalletId: walletId,
        currency: currency,
      });
    }
  };

  const getActionItems = (record: IWallet): MenuProps["items"] => {
    return [
      {
        key: "details",
        label: "Wallet Details",
        icon: <WalletOutlined />,
        onClick: () => {
          handleSetSelectedWalletDetails(
            record.walletId,
            record.currency,
            record.availableBalance,
          );
          showWalletDetails();
        },
      },
      {
        key: "transactions",
        label: "Wallet Transactions",
        icon: <TransactionOutlined />,
        onClick: () =>
          handleWalletTransactions(record.walletId, record.currency),
      },
      {
        key: "withdraw",
        label: "Withdraw",
        icon: <LogoutOutlined />,
        onClick: () => {
          handleSetSelectedWalletDetails(
            record.walletId,
            record.currency,
            record.availableBalance,
          );
          setShowWalletWithdrawal(true);
        },
      },
      {
        key: "deposit",
        label: "Deposit",
        icon: <PlusCircleOutlined />,
        onClick: () => {
          handleSetSelectedWalletDetails(
            record.walletId,
            record.currency,
            record.availableBalance,
          );
          setShowWalletTopUp(true);
        },
      },
    ];
  };

  const columns = [
    {
      title: "Wallet ID",
      dataIndex: "walletId",
      key: "walletId",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Available Balance",
      dataIndex: "availableBalance",
      key: "availableBalance",
    },
    {
      title: "Ledger Balance",
      dataIndex: "ledgerBalance",
      key: "ledgerBalance",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "IsDefault",
      dataIndex: "isDefault",
      key: "isDefault",
      render: (isDefault: boolean) => (isDefault ? "Yes" : "No"),
    },
    {
      title: "IsExternal",
      dataIndex: "isExternal",
      key: "isExternal",
      render: (isExternal: boolean) => (isExternal ? "Yes" : "No"),
    },
    {
      title: "Client ID",
      dataIndex: "clientId",
      key: "clientId",
    },
    {
      title: "Wallet Provider Type",
      dataIndex: "walletProviderType",
      key: "walletProviderType",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: IWallet) => (
        <Space>
          <Dropdown
            menu={{ items: getActionItems(record) }}
            trigger={["click"]}
          >
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <Table
        columns={columns}
        dataSource={wallets}
        rowKey="walletId"
        scroll={{ x: "max-content" }}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
        className="mt-2"
      />
    </div>
  );
};

export default InvestorWalletTable;
