import React, {
  useState,
  Fragment,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import {
  TInvestmentCurrency,
  getInvestmentCurrencies,
} from "../../../custom-hooks/useInvestmentCurrencies";
import {
  postDataWithDotNet,
  postFormDataWithDotNet,
} from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import Financials from "./CreateInvestmentsViews/Financials";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import { errorHandler } from "../../../helpers/errorHandler";
import { ReactComponent as Cancel } from "../../../assets/svg/icons/cancel.svg";
import CreateInvestmentModal from "./CreateInvestmentModal/CreateInvestmentModal";
import { ROLE_TYPES } from "../../../helpers/roleTypes";
import { appInsights } from "../../../config/appInsights";
import CustomSearchDropDown from "../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import Loader from "../../../NewComponents/Loader/Loader.component";
import { RootState } from "../../../redux/store";

import styles from "./InvestmentProduct.module.scss";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import {
  useInvestmentProductSubtypeDetails,
  useInvestmentProductSubtypes,
  useInvestmentProductTypes,
} from "../../../custom-hooks/useDebtFund";
import CustomInputField from "../../../NewComponents/CustomHTMLElements/CustomInputField";
import { string } from "yup";
import { onChangeFile } from "../../../helpers/convertToFormData";
import { handleSwalErrorAlert } from "../../../helpers";
import { v4 as uuidv4 } from "uuid";

export type TInvestmentProductDetails = {
  externalWalletProvider: any;
  systemWalletProvider?: any;
  depositType: any;
  monthlyPrematureLiquidationRate: number;
  endOfTenurePrematureLiquidationRate: number;
  coreBankingProductId: number;
  coreBankingTenantId: string;
  currencyCode: string;
  label?: string;
  maximumInvestment: number;
  minimumInvestment: number;
  value?: string;
  isLocked?: string;
  id?: number;
};

export type TCreateInvestmentProductState = {
  id?: number;
  name: string;
  startDate: string;
  endDate: string;
  objective: string;
  issuers: string;
  description: string;
  targetInvestors: string;
  investmentProductTypeId: number | null;
  investmentProductSubTypeId: number | null;
  investmentProductSubTypeDetailId: number | null;
  investmentProductDetails: TInvestmentProductDetails[];
  termSheetFile?: {
    file: File | undefined;
    fileName: string;
  };
};

const CreateInvestmentProduct = () => {
  const [modalActionType, setModalActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [saveDraftError, setSaveDraftError] = useState("");
  const [state, setState] = useState<TCreateInvestmentProductState>({
    name: "",
    startDate: "",
    endDate: "",
    objective: "",
    issuers: "",
    description: "",
    targetInvestors: "",
    investmentProductDetails: [],
    investmentProductTypeId: null,
    investmentProductSubTypeId: null,
    investmentProductSubTypeDetailId: null,
    termSheetFile: undefined,
  });

  const [isPickingDocument, setIsPickingDocument] = useState(false);

  const dismissModalRef = useRef<HTMLButtonElement>(null);
  const history = useHistory();

  const { loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer,
  );

  const {
    data: investmentProductTypes,
    isError: investmentProductTypeError,
    isLoading: investmentProductTypeLoading,
  } = useInvestmentProductTypes();

  const {
    data: investmentProductSubTypes,
    isError: investmentProductSubTypeError,
    isLoading: investmentProductSubTypeLoading,
  } = useInvestmentProductSubtypes(state.investmentProductTypeId);

  const {
    data: investmentProductSubTypeDetails,
    isError: investmentProductSubTypeDetailError,
    isLoading: investmentProductSubTypeDetailLoading,
  } = useInvestmentProductSubtypeDetails(state.investmentProductSubTypeId);

  const getOptions = () => {
    return new Promise((resolve, reject) => {
      getInvestmentCurrencies()
        .then((data) => {
          resolve(
            data?.data.map((detail: TInvestmentCurrency) => ({
              value: detail?.currency_code,
              label: detail?.currency_name,
            })),
          );
        })
        .catch(reject);
    });
  };

  const handleDropdownChange = (selectedOptions: any) => {
    const existingCurrencyCodes = state.investmentProductDetails.map(
      (detail: any) => detail.value,
    );
    // gets d value of the new currency you are adding => empty if U r removing currency
    const currencyToBeAdded = selectedOptions.filter(
      (option: any) => !existingCurrencyCodes.includes(option.value),
    );

    // gets d value of an existing currency you are removing from the list => empty if U r adding
    const currencyToBeRemoved = state.investmentProductDetails.filter(
      (detail: any) =>
        !selectedOptions.find((option: any) => option.value === detail.value),
    );

    setState({
      ...state,
      investmentProductDetails: [
        ...state.investmentProductDetails.filter(
          (detail: any) => !currencyToBeRemoved.includes(detail),
        ), // Keep options not removed
        ...currencyToBeAdded.map((option: { value: any; label: any }) => ({
          ...option,
          currency_code: option.value,
          label: option.label,
        })),
      ],
    });
  };

  const handleSubmitDraft = async () => {
    setLoading(true);
    const reqBody = { ...state };

    // remove unwanted propertes from the payload
    const refinedProductDetails = reqBody.investmentProductDetails.map(
      (item: TInvestmentProductDetails) => ({
        coreBankingProductId: Number(item?.coreBankingProductId),
        coreBankingTenantId: item?.coreBankingTenantId,
        currencyCode: item?.currencyCode,
        maximumInvestment: Number(item?.maximumInvestment),
        minimumInvestment: Number(item?.minimumInvestment),
        // investment_size: Number(item?.investment_size),
        externalWalletProvider: Number(item?.externalWalletProvider),
        depositType: Number(item?.depositType),
        monthlyPrematureLiquidationRate: item?.monthlyPrematureLiquidationRate,
        endOfTenurePrematureLiquidationRate:
          item?.endOfTenurePrematureLiquidationRate,
      }),
    );

    reqBody.investmentProductDetails = refinedProductDetails;
    reqBody.startDate = moment(reqBody?.startDate).format("DD/MM/YYYY");
    reqBody.endDate = moment(reqBody?.endDate).format("DD/MM/YYYY");

    const formData: any = new FormData();
    formData.append("InvestmentProductDto", JSON.stringify(reqBody)); // Stringify the state

    if (state.termSheetFile?.file) {
      formData.append(
        "TermSheet",
        state.termSheetFile.file,
        state.termSheetFile.fileName,
      );
    }

    console.log({ formData: formData.entries() });

    try {
      const response = await postFormDataWithDotNet(
        ajaxEndpoints.FUNDER_INVESTMENT_PRODUCT,
        formData,
      );

      setSuccessMessage(response.message);
      setTimeout(() => {
        history.push("/investments");
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CreateInvestmentProduct.jsx",
        },
      });

      setSaveDraftError(errorHandler(error));
      setTimeout(() => {
        setSaveDraftError("");
      }, 3000);
    }
    setLoading(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleChangeInvestmentTypeParams = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    if (name === "investmentProductTypeId") {
      setState({
        ...state,
        [name]: Number(value),
        investmentProductSubTypeId: null,
        investmentProductSubTypeDetailId: null,
      });
    } else {
      setState({ ...state, [name]: Number(value) });
    }
  };

  const discardChanges = (e: FormEvent) => {
    e.preventDefault();
    if (dismissModalRef && dismissModalRef.current) {
      dismissModalRef.current.click();
    }
    setState({
      name: "",
      objective: "",
      issuers: "",
      description: "",
      targetInvestors: "",
      startDate: "",
      endDate: "",
      investmentProductDetails: [],
      investmentProductTypeId: null,
      investmentProductSubTypeId: null,
      investmentProductSubTypeDetailId: null,
    });
  };

  async function onChangeTermSheet(e: any) {
    if (isPickingDocument) return; // Prevent multiple invocations
    setIsPickingDocument(true); // Set document picking status to true
    const errorCallback = (error: any) => {
      handleSwalErrorAlert(error);
      appInsights.trackException({
        exception: new Error(error),
        properties: { fileName: "Financials.tsx" },
      });
      setIsPickingDocument(false); // Reset document picking status
    };

    const setInfoCallback = (file: File, fileName: string) => {
      setState({
        ...state,
        termSheetFile: {
          file,
          fileName,
        },
      });
      setIsPickingDocument(false); // Reset document picking status
    };

    await onChangeFile(e, setInfoCallback, errorCallback);
  }

  const updateInvestmentProductDetails = (
    updatedDetails: TInvestmentProductDetails,
  ) => {
    const updatedList = state.investmentProductDetails.map((item) =>
      item.value === updatedDetails.value ? updatedDetails : item,
    );

    setState({
      ...state,
      investmentProductDetails: updatedList,
    });
  };

  return (
    <Fragment>
      <div
        className={`animated fadeInRight mt-2 ${styles.createInvestmentProduct}`}
      >
        <span
          data-toggle="modal"
          data-target="#createInvestmentModal"
          onClick={() => setModalActionType("cancel")}
        >
          <Cancel /> <span className="cancel-title ml-1">Cancel</span>
        </span>
        <div className="d-flex flex-row flex-wrap mt-3 mb-1">
          {(investmentProductTypeError ||
            investmentProductSubTypeError ||
            investmentProductSubTypeDetailError) && (
            <Alert message={"Error fetching investment type details"} />
          )}
          {saveDraftError && <Alert message={saveDraftError} />}
          {successMessage && <Alert message={successMessage} type="success" />}
          <h3 className="page-title">Create New Fund</h3>

          <div className="d-flex flex-row flex-wrap justify-content-end">
            {loggedInUser?.roles.includes(
              ROLE_TYPES.INVESTMENT_PRODUCT_SETUP,
            ) ? (
              <div>
                <button
                  type="submit"
                  className="btn advancly-white-btn btn-md m-0"
                  onClick={handleSubmitDraft}
                  disabled={loading}
                >
                  Save & Submit
                  {loading && <Loader />}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <h3 className="page-subtitle mb-3 ml-3">Investment Details</h3>
        <form>
          <div className="row col-6 col-md-8 col-sm-8">
            <div className="col-md-12 mt-1">
              <label className="label-txt">Investment Name</label>
              <input
                className="form-control"
                value={state.name}
                name="name"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label className="label-txt">Investment Type</label>
              <CustomSelectDropdown
                onChange={(e) => handleChangeInvestmentTypeParams(e)}
                name="investmentProductTypeId"
                value={state.investmentProductTypeId}
                className="round user__form form-control w-100"
                label={
                  investmentProductTypeLoading
                    ? "Loading...."
                    : "Investment Type"
                }
              >
                <option value="">Select investment type</option>
                {investmentProductTypes &&
                  investmentProductTypes.map((item) => {
                    const { name, id } = item;
                    return (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    );
                  })}
              </CustomSelectDropdown>
            </div>

            <div className="col-md-12 mt-3">
              <label className="label-txt">Investment Subtype</label>
              <CustomSelectDropdown
                onChange={(e) => handleChangeInvestmentTypeParams(e)}
                name="investmentProductSubTypeId"
                value={state.investmentProductSubTypeId}
                className="round user__form form-control w-100"
                label={
                  investmentProductSubTypeLoading
                    ? "Loading...."
                    : "Investment Subtype"
                }
              >
                <option value="">Select investment Subtype</option>
                {investmentProductSubTypes &&
                  investmentProductSubTypes.map((item) => {
                    const { subTypeId, subTypeName } = item;
                    return (
                      <option value={subTypeId} key={subTypeId}>
                        {subTypeName}
                      </option>
                    );
                  })}
              </CustomSelectDropdown>
            </div>

            <div className="col-md-12 mt-3">
              <label className="label-txt">Investment Subtype details</label>
              <CustomSelectDropdown
                onChange={(e) => handleChangeInvestmentTypeParams(e)}
                name="investmentProductSubTypeDetailId"
                value={state.investmentProductSubTypeDetailId}
                className="round user__form form-control w-100"
                label={
                  investmentProductSubTypeDetailLoading
                    ? "Loading...."
                    : "Investment Subtype Details"
                }
              >
                <option value="">Select investment Subtype details</option>
                {investmentProductSubTypeDetails &&
                  investmentProductSubTypeDetails.map((item) => {
                    const { subTypeDetailId, subTypeDetail } = item;
                    return (
                      <option value={subTypeDetailId} key={subTypeDetailId}>
                        {subTypeDetail}
                      </option>
                    );
                  })}
              </CustomSelectDropdown>
            </div>

            <div className={styles.productBasicDetails}>
              <div className="col-md-6">
                <label>Subscription Start date: </label>
                <input
                  type="date"
                  name="startDate"
                  className="form-control"
                  value={state.startDate}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-md-6">
                <div>
                  <label>Subscription End date: </label>
                  <input
                    type="date"
                    className="form-control"
                    name="endDate"
                    value={state.endDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-3 pl-2">
                <label className="label-txt">About this Fund</label>
                <input
                  className="form-control"
                  value={state.description}
                  name="description"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label className="label-txt">Investment Objective</label>
                <input
                  className="form-control"
                  value={state.objective}
                  name="objective"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-md-6 mt-3 pl-2">
                <label className="label-txt">Issuer</label>
                <input
                  className="form-control"
                  value={state.issuers}
                  name="issuers"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label className="label-txt">Target Investors</label>
                <input
                  className="form-control"
                  value={state.targetInvestors}
                  name="targetInvestors"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-md-12 mb-3 mt-3">
                <label className="label-txt">
                  Supporting Term sheet (optional)
                </label>
                <CustomInputField
                  type="file"
                  placeholder=""
                  label=""
                  onChange={onChangeTermSheet}
                  name="termSheet"
                  accept="image/png, image/jpg, image/jpeg, application/pdf"
                  styles={{ paddingBottom: "2rem" }}
                />
              </div>
            </div>

            <hr className={styles.divider}></hr>
            <h3 className="page-subtitle mb-3 ml-3">Financials</h3>
            <div className="col-md-12 my-3">
              <label className="label-txt">
                What currencies are you accepting for this fund:
              </label>

              <div className="col-md-12 p-0">
                <CustomSearchDropDown
                  label="Select Currency"
                  onChange={handleDropdownChange}
                  loadOptions={getOptions}
                  defaultOptions={true}
                  name="user"
                  isShowLabel={false}
                  isMulti={true}
                  isSearchable={false}
                />
              </div>
            </div>

            <div className={styles.financialsContainer}>
              <div className="col-md-12">
                <h3 className="page-subtitle mb-2">Currencies:</h3>
              </div>
              {state?.investmentProductDetails.length < 1 && (
                <div className={styles.noCurrencyErrorContainer}>
                  You have not selected any currencies
                </div>
              )}
              {state?.investmentProductDetails.map((item, index) => (
                <Financials
                  key={index}
                  handleChange={handleChange}
                  state={state}
                  setState={setState}
                  selectedProductDetails={item}
                  updateParentState={updateInvestmentProductDetails}
                />
              ))}
            </div>
          </div>
        </form>
      </div>
      <CreateInvestmentModal
        onSubmit={handleSubmitDraft}
        discardChanges={discardChanges}
        modalActionType={modalActionType}
        setModalActionType={setModalActionType}
        dismissModalRef={dismissModalRef}
        loading={loading}
      />
    </Fragment>
  );
};

export default CreateInvestmentProduct;
