import { useState } from "react";
import styles from "./UploadedDocument.module.scss";
import { ReactComponent as Folder } from "../../assets/svg/icons/folder_2.svg";
import { ReactComponent as Bin } from "../../assets/svg/icons/bin.svg";
import { ReactComponent as Download } from "../../assets/svg/icons/download-icon-round.svg";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { getData } from "./../../newApis/apiMethods";
import Loader from "./../Loader/Loader.component";
import { appInsights } from "../../config/appInsights";

export const DeleteUploadedDocument = ({
  name,
  deleteFile,
  hideDeleteIcon,
}) => {
  return (
    <div className={styles.uploadedDocCard}>
      <div className={styles.left}>
        <Folder />
        <span>{name}</span>
      </div>
      {hideDeleteIcon ? null : (
        <div className={styles.right} onClick={deleteFile}>
          <Bin />
        </div>
      )}
    </div>
  );
};

export const DownloadUploadedDocument = ({ name, pathname, handleView }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  // const [loading, setLoading] = useState(false);

  // const prepareDownloadLink = (content, fileName) => {
  //   console.log("predowload 1 checking content", content);
  //   console.log("predowload 1 checking file name", fileName);
  //   const appendSource = fileName.endsWith(".pdf")
  //     ? "application/pdf"
  //     : "application/msword";

  //   const linkSource = `data:${appendSource};base64,${content}`;
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = linkSource;
  //   downloadLink.download = fileName;
  //   downloadLink.click();
  //   setIsDownloading(false);
  // };

  // const prepareDownloadLink2 = (content, fileName) => {
  //   const fileExtension = fileName.split(".").pop()?.toLowerCase();
  //   console.log("checking content", content);
  //   console.log("checking file extension", fileExtension);

  //   let appendSource = "";

  //   // Determine the MIME type based on the file extension
  //   if (fileExtension === "pdf") {
  //     appendSource = "application/pdf";
  //   } else if (fileExtension === "doc" || fileExtension === "docx") {
  //     appendSource = "application/msword";
  //   } else if (fileExtension === "jpg" || fileExtension === "jpeg") {
  //     appendSource = "image/jpeg";
  //   } else if (fileExtension === "png") {
  //     appendSource = "image/png";
  //   } else {
  //     // Default MIME type
  //     appendSource = "application/octet-stream";
  //   }

  //   // Create the base64 link
  //   const linkSource = `data:${appendSource};base64,${content}`;
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = linkSource;
  //   downloadLink.download = fileName;
  //   downloadLink.click();
  //   setIsDownloading(false);
  // };

  const handleDownload = (currentFileUrl, currentFileName) => {
    if (!currentFileUrl) {
      alert("No file Available to download");
      return;
    }

    // Create a temporary <a> element
    const link = document.createElement("a");

    // Set the download link and filename
    link.href = currentFileUrl;
    link.download = currentFileName || "download"; // Default to 'download' if no file name is set

    // Append the <a> element to the body and trigger a click
    document.body.appendChild(link);
    link.click();

    // Remove the <a> element from the DOM after the download is triggered
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  const downloadFile = async (fileName) => {
    try {
      setIsDownloading(true);
      const data = await getData(
        `${ajaxEndpoints.DOCUMENT_URL}?file_path=${fileName}`
      );
      handleDownload(data?.data?.file_url, data?.data?.file_name);
      // prepareDownloadLink(data.base64_string, fileName);
      // downloadFileAndConvertToBase64(data?.data?.file_url);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadedDocumentCard.component.jsx",
        },
      });
      setIsDownloading(false);
    }
  };
  return (
    <div className={styles.uploadedDocCard}>
      <div className={styles.left}>
        <Folder />
        <span>{name}</span>
      </div>
      <span className={styles.viewDocument} onClick={handleView}>
        View
      </span>
      <div
        className={`${styles.right} ${isDownloading ? "disabled" : ""}`}
        onClick={() => downloadFile(pathname)}
      >
        {isDownloading ? <Loader /> : <Download />}
      </div>
    </div>
  );
};
export const DownloadOfferLetterDocument = ({
  name,
  pathname,
  documentName,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleDownload = (currentFileUrl, currentFileName) => {
    if (!currentFileUrl) {
      alert("No file Available to download");
      return;
    }

    const link = document.createElement("a");

    link.href = currentFileUrl;
    link.download = currentFileName || "download"; // Default to 'download' if no file name is set
    document.body.appendChild(link);
    link.click();
    setIsDownloading(false);

    // Remove the <a> element from the DOM after the download is triggered
    document.body.removeChild(link);
  };

  // const downloadFileAndConvertToBase64 = async (url) => {
  //   setLoading(true);
  //   try {
  //     // Fetch the image file from the URL
  //     const response = await fetch(url);
  //     if (!response.ok) {
  //       throw new Error("File download failed");
  //     }

  //     // Convert the response to a Blob
  //     const blob = await response.blob();

  //     // Convert the Blob to base64
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const base64String = reader.result;
  //       // Remove the 'data:[mime-type];base64,' prefix
  //       const base64Content = base64String.split(",")[1];

  //       if (base64String.length > 0) {
  //         prepareDownloadLink(base64String, name);
  //         // prepareDownloadLink2(base64String, name);
  //       }

  //       setLoading(false);
  //     };

  //     reader.readAsDataURL(blob);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     setLoading(false);
  //   }
  // };

  const prepareDownloadLink = (content, fileName) => {
    const appendSource = fileName.endsWith(".pdf")
      ? "application/pdf"
      : "application/msword";

    const linkSource = `data:${appendSource};base64,${content}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setIsDownloading(false);
  };

  const downloadFile = async (fileName) => {
    try {
      setIsDownloading(true);
      const data = await getData(
        `${ajaxEndpoints.DOCUMENT_URL}?file_path=${fileName}`
      );
      handleDownload(data?.data?.file_url, data?.data?.file_name);
      // prepareDownloadLink(data.base64_string, fileName);

      // prepareDownloadLink(data.base64_string, fileName);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadedDocumentCard.component.jsx",
        },
      });
      setIsDownloading(false);
    }
  };
  return (
    <div className={styles.uploadedDocCard}>
      <div className={styles.left}>
        <Folder />
        <span>{documentName}</span>
      </div>
      <div
        className={`${styles.right} ${isDownloading ? "disabled" : ""}`}
        onClick={() => downloadFile(pathname)}
      >
        {isDownloading ? <Loader /> : <Download />}
      </div>
    </div>
  );
};
