import { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../config/appInsights";
import CustomSearchDropDown from "../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { useLocation } from "react-router-dom";
import { postData, putData } from "../../../../../newApis/dotNetApiMethods";
import { handleFormatAmountNoCurrency } from "../../../../../helpers/formatter";
import { NumericFormat } from "react-number-format";
import useInvestmentCurrencies from "../../../../../custom-hooks/useInvestmentCurrencies";
import useLoanClassification from "../../../../../custom-hooks/useLoanClassification";

import styles from "./createIndebtedness.module.scss";

const schema = yup.object().shape({
  bank: yup.string().required("Please enter bank"),
  loanRef: yup.string(),
  loanAmount: yup.string().required("Please select currency"),
  // interestRate: yup.string(),
  productType: yup.string().required("Please select product type"),
  outstandingAmount: yup.string().required("Please enter oustanding amount"),
  tenor: yup.number().required("Please enter interest rate"),
});

const CreateIndebtednessModal = (props: any) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState(
    props?.dataRecord?.oldData?.currency ?? ""
  );
  const [selectedLoanClass, setSelectedLoanClass] = useState(
    props?.dataRecord?.oldData?.loanStatus ?? ""
  );

  const location = useLocation();
  const state = location?.state as { id: number };

  const { data: currencies } = useInvestmentCurrencies();
  const { data: loanClassification } = useLoanClassification();

  const cleanUpCurrencies = (
    dataInQuestion:
      | {
          currency_code: string;
          currency_name: string;
        }[]
      | undefined
  ) => {
    if (dataInQuestion) {
      const result = dataInQuestion.map((_currencyObject) => {
        return {
          label: _currencyObject?.currency_code,
          value: _currencyObject?.currency_code,
        };
      });

      return result;
    }

    return [];
  };

  const cleanUpLoanClassifications = (
    dataInQuestion: { id: number; value: string }[]
  ) => {
    if (dataInQuestion) {
      const result = dataInQuestion.map((_loanClass) => {
        return {
          label: _loanClass?.value,
          value: _loanClass?.value,
        };
      });

      return result;
    }

    return [];
  };

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const handleDropdownChange = (selectedItem: {
    label: string;
    value: string;
  }) => {
    setSelectedCurrency(selectedItem?.value);
  };

  const handleLoanClassificationChange = (selectedItem: {
    label: string;
    value: string;
  }) => {
    setSelectedLoanClass(selectedItem?.value);
  };

  const onSubmit = (values: any) => {
    if (props?.dataRecord?.type === "create") {
      return handleCreate(values);
    }

    return handleEdit(values);
  };

  const handleCreate = async (values: any) => {
    setIsLoading(true);
    if (!selectedCurrency) {
      setIsLoading(false);
      return setErrorMessage("You have not selected currency");
    }

    if (!selectedLoanClass) {
      setIsLoading(false);
      return setErrorMessage("You have not selected loan classification");
    }
    try {
      const payload = {
        loanApplicationId: state?.id,
        bank: values?.bank,
        loanRef: values?.loanRef,
        loanAmount: Number(
          handleFormatAmountNoCurrency(String(values?.loanAmount))
        ),
        currency: selectedCurrency,
        // interestRate: values?.interestRate,
        productType: values?.productType,
        outstandingAmount: Number(
          handleFormatAmountNoCurrency(String(values?.outstandingAmount))
        ),
        tenor: values?.tenor,
        loanStatus: selectedLoanClass,
      };

      const response = await postData(
        ajaxEndpoints.CREATE_LOANS_BY_OTHER_LENDERS,
        payload
      );

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "CreateIndebtedness" },
      });
    }
    setIsLoading(false);
  };

  const handleEdit = async (values: any) => {
    setIsLoading(true);
    if (!selectedCurrency) {
      setIsLoading(false);
      return setErrorMessage("You have not selected currency");
    }

    if (!selectedLoanClass) {
      setIsLoading(false);
      return setErrorMessage("You have not selected loan classification");
    }
    try {
      const payload = {
        id: props?.dataRecord?.oldData?.id,
        loanApplicationId: state?.id,
        bank: values?.bank,
        loanRef: values?.loanRef,
        loanAmount: Number(
          handleFormatAmountNoCurrency(String(values?.loanAmount))
        ),
        currency: selectedCurrency,
        interestRate: values?.interestRate,
        productType: values?.productType,
        outstandingAmount: Number(
          handleFormatAmountNoCurrency(String(values?.outstandingAmount))
        ),
        tenor: values?.tenor,
        loanStatus: selectedLoanClass,
      };

      const response = await putData(
        ajaxEndpoints.UPDATE_LOANS_BY_OTHER_LENDERS,
        payload
      );

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "CreateIndebtedness" },
      });
    }
    setIsLoading(false);
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content" style={{ height: "80vh" }}>
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className={`Modal__title mb-5 ${styles.Title}`}>
            {props?.dataRecord?.type === "create"
              ? "Add Indebtedness"
              : "Edit Indebtedness"}
          </h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <label>Bank</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    name="bank"
                    ref={register}
                    defaultValue={props?.dataRecord?.oldData?.bank}
                    placeholder="Enter Bank"
                  />
                  <span className={styles.errorMessage}>
                    {errors?.bank?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <label>Tenor (In Days)</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    name="tenor"
                    ref={register}
                    defaultValue={props?.dataRecord?.oldData?.tenor}
                    placeholder="Enter Tenor"
                  />
                  <span className={styles.errorMessage}>
                    {errors?.tenor?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <label>Loan Amount</label>
                <div className="form-group inputWithIcon">
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros
                    className="form-control"
                    value={props?.dataRecord?.oldData?.loanAmount}
                    name="loanAmount"
                    getInputRef={register}
                    placeholder="Enter Amount"
                  />
                  <span className={styles.errorMessage}>
                    {errors?.loanAmount?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <label>Currency</label>
                <div className="col-md-12 p-0">
                  <CustomSearchDropDown
                    defaultOptions={cleanUpCurrencies(currencies?.data)}
                    defaultValue={{
                      label: props?.dataRecord?.oldData?.currency,
                      value: props?.dataRecord?.oldData?.currency,
                    }}
                    onChange={handleDropdownChange}
                    isSearchable={false}
                    isMulti={false}
                    placeholder="Select Currency"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-sm-6 col-xs-12">
                <label className="label-txt">Interest Rate (per annum)</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    name="interestRate"
                    ref={register}
                    defaultValue={props?.dataRecord?.oldData?.interestRate}
                  />
                  <span className={styles.errorMessage}>
                    {errors?.interestRate?.message}
                  </span>
                </div>
              </div> */}
              <div className="col-sm-6 col-xs-12">
                <label className="label-txt">Product Type</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    name="productType"
                    ref={register}
                    defaultValue={props?.dataRecord?.oldData?.productType}
                  />
                  <span className={styles.errorMessage}>
                    {errors?.productType?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <label>Outstanding Amount</label>
                <div className="form-group inputWithIcon">
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros
                    className="form-control"
                    value={props?.dataRecord?.oldData?.outstandingAmount}
                    name="outstandingAmount"
                    getInputRef={register}
                  />
                  <span className={styles.errorMessage}>
                    {errors?.outstandingAmount?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <label>Loan Classification</label>
                <div className="col-md-12 p-0">
                  <CustomSearchDropDown
                    defaultOptions={cleanUpLoanClassifications(
                      loanClassification?.data
                    )}
                    defaultValue={{
                      label: props?.dataRecord?.oldData?.loanStatus,
                      value: props?.dataRecord?.oldData?.loanStatus,
                    }}
                    onChange={handleLoanClassificationChange}
                    isSearchable={false}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-xs-12">
                <label>
                  Loan Reference{" "}
                  <small className={styles.LabelSubText}>(Optional)</small>
                </label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    name="loanRef"
                    defaultValue={props?.dataRecord?.oldData?.loanRef}
                    ref={register}
                  />
                  <span className={styles.errorMessage}>
                    {errors?.loanRef?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className="Form__action-buttons m-t-2">
              <button type="submit" className="btn zoom-element btn login-btn">
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateIndebtednessModal;
