// useCountryStates.ts
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";

type paramProps = {
  countryId: number;
};

const getCountryStates = async (countryId: number) => {
  const { data } = await getData(
    `${ajaxEndpoints.COUNTRY_STATES}?iso2_code_country=NG`,
  );
  return data;
};

export default function useCountryStates(countryId: number) {
  return useQuery(
    ["getCountryStates", countryId],
    () => getCountryStates(countryId),
    {
      refetchOnWindowFocus: true, // Refetch on window focus
    },
  );
}
