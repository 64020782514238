import { useEffect, useState } from "react";

import { RefetchOptions } from "react-query/types/core/query";

import styles from "./businessDocumentation.module.scss";
import {
  IBusinessDocument,
  IBusinessDocumentation,
} from "../../../DigitalBank/Interfaces";
import { appInsights } from "../../../../config/appInsights";
import useDocumentViewer from "../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { DownloadUploadedDocument } from "../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import { documentApprovalStatus } from "../../util";
import { auditRoleList, isAudit } from "../../../../helpers/roleTypes";
import { v4 as uuidv4 } from "uuid";

interface IBusinessDocumentationProps {
  data: IBusinessDocumentation;
  fetchData: (options?: RefetchOptions) => Promise<any | undefined>;
  setActionData: React.Dispatch<
    React.SetStateAction<
      | {
          actionType: string;
          data: IBusinessDocument;
        }
      | undefined
    >
  >;
}

const BusinessDocumentation = ({
  data,
  setActionData,
}: IBusinessDocumentationProps) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "BusinessDocumentation.tsx",
      isLoggedIn: true,
    });
  }, []);

  const { handleViewDocument } = useDocumentViewer();

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  return (
    <div className="table-view speed-1x animated fadeInRight">
      <div className={styles.uploadedDocsContainer}>
        {data?.businessDocuments && data?.businessDocuments?.length ? (
          <div>
            {data?.businessDocuments?.map((uploadedDocument, _id) => (
              <div className="row mb-5" key={_id}>
                <div className="col-12 d-flex justify-content-between">
                  <label className="form-label">
                    {_id + 1}. {uploadedDocument?.documentName}
                  </label>
                </div>
                <div className="col-12 d-flex">
                  <div className="d-flex" key={uuidv4()}>
                    <div className={styles.downloadUploadDocContainer}>
                      <DownloadUploadedDocument
                        name={uploadedDocument?.fileName}
                        pathname={uploadedDocument?.documentUrl}
                        //   isViewDocument
                        handleView={() =>
                          handleViewDocument(
                            false,
                            uploadedDocument?.documentUrl
                          )
                        }
                      />
                    </div>

                    <div className="d-flex ml-5">
                      <div className="d-flex">
                        {uploadedDocument?.status?.id ===
                          documentApprovalStatus.DECLINED && (
                          <button
                            className="btn advancly-red-btn btn-md"
                            disabled
                          >
                            Declined
                          </button>
                        )}
                        {uploadedDocument?.status?.id ===
                          documentApprovalStatus.APPROVED && (
                          <button
                            className="btn advancly-green-btn btn-md"
                            disabled
                          >
                            Approved
                          </button>
                        )}
                        {/* {(uploadedDocument.status.id ===
                          documentApprovalStatus.APPROVED ||
                          item.approval_status ===
                            documentApprovalStatus.DECLINED) && (
                          <div
                            className={styles.commentsAction}
                            onClick={(e) => handleViewComments(e, item)}
                          >
                            <MessageIcon className={styles.messageIcon} />
                            <span className={styles.commentText}>Comment</span>
                          </div>
                        )} */}
                      </div>

                      {uploadedDocument?.status?.id ===
                        documentApprovalStatus.PENDING &&
                        !isAudit(
                          [...loggedInUser?.roles],
                          [...auditRoleList]
                        ) && (
                          <>
                            <button
                              className="btn advancly-btn btn-md"
                              data-toggle="modal"
                              data-target="#businessDocumentsApprovalModal"
                              onClick={() =>
                                setActionData({
                                  actionType: "approve",
                                  data: uploadedDocument,
                                })
                              }
                            >
                              Approve
                            </button>
                            <button
                              className="btn advancly-off-2-btn btn-md ml-3"
                              data-toggle="modal"
                              data-target="#businessDocumentsApprovalModal"
                              onClick={() =>
                                setActionData({
                                  actionType: "decline",
                                  data: uploadedDocument,
                                })
                              }
                            >
                              Decline
                            </button>
                          </>
                        )}
                      {/* <span className={styles.documentTimeStamp}>
                        {moment(item?.uploaded_at).format("lll")}
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-100 d-flex align-items-center justify-content-center py-3">
            <h3 className="font-weight-bold">
              Customer is yet to upload any document
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessDocumentation;
