import { useEffect } from "react";
import useDocumentViewer from "../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  IPartners,
  boardOfDirectorsDocumentType,
} from "../../../DigitalBank/Interfaces";
import Accordion from "../../../DigitalBank/Components/Accordion";
import { appInsights } from "../../../../config/appInsights";
import CustomInputField from "../../../CustomHTMLElements/CustomInputField";
import { DownloadUploadedDocument } from "../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import { auditRoleList, isAudit } from "../../../../helpers/roleTypes";
import { documentApprovalStatusString } from "../../util";

interface IPartnersProps {
  data: IPartners[];
  setActionData: React.Dispatch<React.SetStateAction<{}>>;
  funderId: number;
}

const Partners = ({
  data: partners,
  setActionData,
  funderId,
}: IPartnersProps) => {
  const { handleViewDocument } = useDocumentViewer();

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Partners.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="table-view speed-1x animated fadeInRight">
      {partners.map((_item, _id) => (
        <div className="mb-3" key={_id}>
          <Accordion
            title={`Partners (${_id + 1})`}
            subTitle="Click the - icon to see more information on the partner"
          >
            <div className="row">
              <div className="col-md-5">
                <CustomInputField
                  label="First Name"
                  type="text"
                  name="firstName"
                  defaultValue={_item?.firstName!}
                  disabled
                />

                <CustomInputField
                  label="Gender"
                  type="text"
                  name="gender"
                  defaultValue={_item?.gender!}
                  disabled
                />
                <CustomInputField
                  label="Date Of Birth"
                  type="text"
                  name="dateOfBirth"
                  defaultValue={
                    _item?.dateOfBirth ? _item?.dateOfBirth.split("T")[0] : ""
                  }
                  disabled
                />
                <CustomInputField
                  label="Residential Address"
                  type="text"
                  name="address"
                  defaultValue={_item?.address!}
                  disabled
                />
                <CustomInputField
                  label="Identification"
                  type="text"
                  name="identityNumber"
                  defaultValue={_item?.identityNumber!}
                  disabled
                />
                <CustomInputField
                  label="Country"
                  type="text"
                  name="countryCode"
                  defaultValue={_item?.countryCode!}
                  disabled
                />

                <div className="mb-5">
                  <DownloadUploadedDocument
                    name={"ID Upload"}
                    pathname={_item?.governmentIdFilePath}
                    handleView={() =>
                      handleViewDocument(false, _item?.governmentIdFilePath)
                    }
                  />

                  <div className="d-flex ml-5">
                    <div className="d-flex">
                      {_item.governmentIdApprovalStatus ===
                        documentApprovalStatusString?.DECLINED && (
                        <button
                          className="btn advancly-red-btn btn-md"
                          disabled
                        >
                          Declined
                        </button>
                      )}
                      {_item.governmentIdApprovalStatus ===
                        documentApprovalStatusString?.APPROVED && (
                        <button
                          className="btn advancly-green-btn btn-md"
                          disabled
                        >
                          Approved
                        </button>
                      )}
                    </div>

                    {_item.governmentIdApprovalStatus ===
                      documentApprovalStatusString?.PENDING &&
                      !isAudit(
                        [...loggedInUser?.roles],
                        [...auditRoleList]
                      ) && (
                        <>
                          <button
                            type="button"
                            className="btn advancly-btn btn-md"
                            data-toggle="modal"
                            data-target="#funderPeopleDocumentsApprovalModal"
                            // disabled={handleDisableDocumentAction(loggedInUser)}
                            onClick={() =>
                              setActionData({
                                actionType: "approve",
                                data: {
                                  data: {
                                    id: _item?.funderPersonId,
                                    documentType: _item?.govtIdentityType,
                                    funderId,
                                  },
                                  documentType:
                                    boardOfDirectorsDocumentType?.GOVERNMENT_ID,
                                },
                              })
                            }
                          >
                            Approve
                          </button>
                          <button
                            type="button"
                            className="btn btn advancly-off-2-btn btn-md ml-3"
                            data-toggle="modal"
                            data-target="#funderPeopleDocumentsApprovalModal"
                            // disabled={handleDisableDocumentAction(loggedInUser)}
                            onClick={() =>
                              setActionData({
                                actionType: "decline",
                                data: {
                                  data: {
                                    id: _item?.funderPersonId,
                                    documentType: _item?.govtIdentityType,
                                    funderId,
                                  },
                                  documentType:
                                    boardOfDirectorsDocumentType?.GOVERNMENT_ID,
                                },
                              })
                            }
                          >
                            Decline
                          </button>
                        </>
                      )}
                  </div>
                </div>

                <div className="mb-5">
                  <DownloadUploadedDocument
                    name={"Proof of Address"}
                    pathname={_item?.proofOfAddressFilePath}
                    handleView={() =>
                      handleViewDocument(false, _item?.proofOfAddressFilePath)
                    }
                  />

                  <div className="d-flex ml-5">
                    <div className="d-flex">
                      {_item.proofOfAddressApprovalStatus ===
                        documentApprovalStatusString?.DECLINED && (
                        <button
                          className="btn advancly-red-btn btn-md"
                          disabled
                        >
                          Declined
                        </button>
                      )}
                      {_item.proofOfAddressApprovalStatus ===
                        documentApprovalStatusString?.APPROVED && (
                        <button
                          className="btn advancly-green-btn btn-md"
                          disabled
                        >
                          Approved
                        </button>
                      )}
                    </div>

                    {_item.proofOfAddressApprovalStatus ===
                      documentApprovalStatusString?.PENDING &&
                      !isAudit(
                        [...loggedInUser?.roles],
                        [...auditRoleList]
                      ) && (
                        <>
                          <button
                            type="button"
                            className="btn advancly-btn btn-md"
                            data-toggle="modal"
                            data-target="#funderPeopleDocumentsApprovalModal"
                            // disabled={handleDisableDocumentAction(loggedInUser)}
                            onClick={() =>
                              setActionData({
                                actionType: "approve",
                                data: {
                                  data: {
                                    id: _item?.funderPersonId,
                                    documentType: _item?.govtIdentityType,
                                    funderId,
                                  },
                                  documentType:
                                    boardOfDirectorsDocumentType?.PROOF_OF_ADDRESS,
                                },
                              })
                            }
                          >
                            Approve
                          </button>
                          <button
                            type="button"
                            className="btn btn advancly-off-2-btn btn-md ml-3"
                            data-toggle="modal"
                            data-target="#funderPeopleDocumentsApprovalModal"
                            // disabled={handleDisableDocumentAction(loggedInUser)}
                            onClick={() =>
                              setActionData({
                                actionType: "decline",
                                data: {
                                  data: {
                                    id: _item?.funderPersonId,
                                    documentType: _item?.govtIdentityType,
                                    funderId,
                                  },
                                  documentType:
                                    boardOfDirectorsDocumentType?.PROOF_OF_ADDRESS,
                                },
                              })
                            }
                          >
                            Decline
                          </button>
                        </>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <CustomInputField
                  label="Last Name"
                  type="text"
                  name="lastName"
                  defaultValue={_item?.lastName!}
                  disabled
                />

                <CustomInputField
                  label="Phone Number"
                  type="text"
                  name="phoneNumber"
                  defaultValue={_item?.phoneNumber!}
                  disabled
                />

                <CustomInputField
                  label="City"
                  type="text"
                  name="city"
                  defaultValue={_item?.city!}
                  disabled
                />

                <CustomInputField
                  label="BVN"
                  type="text"
                  name="bvn"
                  defaultValue={_item?.bvn!}
                  disabled
                />

                <CustomInputField
                  label="State"
                  type="text"
                  name="state"
                  defaultValue={_item?.state!}
                  disabled
                />
              </div>
            </div>
          </Accordion>
        </div>
      ))}

      {partners && partners.length < 1 && (
        <div className="w-100 d-flex align-items-center justify-content-center py-3">
          <h3 className="font-weight-bold">
            Customer is yet to submit Partners Information
          </h3>
        </div>
      )}
    </div>
  );
};

export default Partners;
