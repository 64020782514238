import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { aMonthAgo, today } from "../../helpers/dateTimeControllers";
import { getTokenFromLocalStorage } from "../../helpers/localStorage";
import { BASE_URL, REGULATORY_REPORT_BASE_URL } from "../../appConstants";
import { appInsights } from "../../config/appInsights";
import ButtonLoader from "../../UI/Loaders/Loaders";
import useIncomeInterestReport from "../../custom-hooks/useIncomeInterestReport";
import useTenants, {
  useTrialBalanceReport,
} from "../../custom-hooks/useTrialBalance";
import useRegulatoryReports from "../../custom-hooks/useRegulatoryReports";
import { DOWNLOAD_REGULATORY_REPORT } from "../../api/ajax-endpoints";

const Regulatory = () => {
  const history = useHistory();
  const [endDate, setEndDate] = useState(today);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedRegulatoryReportType, setSelectedRegulatoryReportType] =
    useState("");
  const [tenantId, setTenantId] = useState("bishopgate");

  const { data, status, error } = useRegulatoryReports();
  const {
    data: tenants,
    status: tenantsStatus,
    error: tenantsError,
  } = useTenants();

  const handleDownload = () => {
    setDownloadLoading(true);
    let token = getTokenFromLocalStorage();
    let queryParams = "";
    if (endDate && selectedRegulatoryReportType && tenantId) {
      queryParams = `?endDate=${endDate}&specificReport=${selectedRegulatoryReportType}&tenantId=${tenantId}`;
    }

    fetch(
      REGULATORY_REPORT_BASE_URL +
        `${DOWNLOAD_REGULATORY_REPORT}${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
      },
    )
      .then((response) => {
        setDownloadLoading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `RegulatoryReport as at ${endDate}.xlsx`,
            );
            link.click();
          });
        }
        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }
        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Regulatory.tsx",
          },
        });
        // this.setState({ loading: false, error: "Something went wrong. Please check your internet connection and try again" });
        setDownloadLoading(false);
      });
  };

  if (tenantsError) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            tenants, Please Reload The Page{" "}
          </h4>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            Regulatory Report Types, Please Reload The Page{" "}
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Regulatory Reports Header*/}
        <NavLink to="/regulatory">
          <h3 className="page-title mb-4">Regulatory Report</h3>
        </NavLink>

        <div className="d-flex flex-row flex-wrap ">
          <form
            className="d-flex flex-row m-t-md"
            // onSubmit={filterData}
          >
            <div
              className="d-flex flex-row flex-wrap justify-content-start"
              style={{ alignItems: "flex-end" }}
            >
              <div className="m-l-1 m-r-1 m-b-1 form-group">
                <label htmlFor="tenant">Select Tenant:</label>
                <br />
                <select
                  onChange={(e) => {
                    setTenantId(e.target.value);
                  }}
                  id="tenant"
                  className="table-fetch-select form-control"
                  style={{ width: "100%" }}
                  defaultValue="default"
                >
                  <option value=""></option>
                  {tenants &&
                    tenants.map((item: any) => {
                      const { full_name, tenant_id } = item;
                      return (
                        <option
                          selected={tenant_id === "bishopgate"}
                          value={tenant_id}
                          key={tenant_id}
                        >
                          {full_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="m-l-1 m-r-1 m-b-1 form-group">
                <label htmlFor="tenant">Regulatory Report Type:</label>
                <br />
                <select
                  onChange={(e) => {
                    setSelectedRegulatoryReportType(e.target.value);
                  }}
                  id="tenant"
                  className="table-fetch-select form-control"
                  style={{ width: "100%" }}
                >
                  <option value=""></option>
                  {data &&
                    data.map((item: any) => {
                      const { id, name } = item;
                      return (
                        <option value={name} key={id}>
                          {name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="m-l-1 m-r-1 m-b-1 form-group">
                <label htmlFor="table-fetch">End Date:</label>
                <input
                  type="date"
                  className="form-control"
                  defaultValue={today}
                  max={today}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              {/* Start of download button */}
              <button
                type="button"
                className="btn advancly-white-btn"
                onClick={handleDownload}
                disabled={
                  status === "loading" ||
                  tenantsStatus === "loading" ||
                  downloadLoading ||
                  !selectedRegulatoryReportType
                }
                style={{ marginTop: "33px" }}
              >
                <i className="fas fa-download m-r-1" />
                Download {downloadLoading && <ButtonLoader />}
              </button>

              {/* end of download button */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Regulatory;
