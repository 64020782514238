import CustomInputField from "../../../CustomHTMLElements/CustomInputField";
import CustomOnboardingAccordion from "../../Components/CustomOnboardingAccordion";
import CustomTextArea from "../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import useCountryCodes from "../../../../custom-hooks/useCountryCodes";

import styles from "./SeniorManagement.module.scss";

export default function SeniorManagement({
  seniorManagement,
  isFromCreditAnalysis = false,
}) {
  const { data } = useCountryCodes();

  return (
    <div className={!isFromCreditAnalysis ? "onboardingInnerLayout" : ""}>
      {seniorManagement &&
        seniorManagement.length > 0 &&
        seniorManagement.map((_item, index) => (
          <CustomOnboardingAccordion
            key={index}
            title={`${_item?.first_name} ${_item?.last_name}`}
          >
            <form>
              <div className="row">
                <div className="col-12">
                  <CustomInputField
                    type="text"
                    label="Name"
                    defaultValue={`${_item?.first_name} ${_item?.last_name}`}
                    readOnly
                  />
                </div>
                {/* <div className="col-12 col-lg-6">
                  <CustomInputField
                    type="text"
                    label="Country"
                    readOnly
                    defaultValue={getCountry(_item?.country_code, data)}
                  />
                </div> */}
                <div className="col-12 col-lg-6">
                  <CustomInputField
                    type="text"
                    label="Role"
                    placeholder="E.g Chairman"
                    readOnly
                    defaultValue={_item?.role}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <CustomInputField
                    type="text"
                    label="Academic & Professional Qualifications"
                    readOnly
                    defaultValue={_item?.educational_qualification}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <CustomInputField
                    type="text"
                    label="Period on the company's board"
                    placeholder="E.g. 4 years"
                    defaultValue={_item?.period_in_company_board}
                    readOnly
                  />
                </div>

                <div className="col-12">
                  <CustomTextArea
                    label="Work Experience"
                    readOnly
                    defaultValue={_item?.work_experience}
                  />
                </div>
                <div className="col-12">
                  <CustomTextArea
                    label="Experience relevant to the role/business"
                    readOnly
                    defaultValue={_item?.experience_relevant_to_role}
                  />
                </div>
              </div>
            </form>
          </CustomOnboardingAccordion>
        ))}

      {seniorManagement && seniorManagement.length < 1 && (
        <div className="w-100 d-flex align-items-center justify-content-center py-3">
          <h3 className="font-weight-bold">
            Aggregator is yet to submit senior management information
          </h3>
        </div>
      )}
    </div>
  );
}
