import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getNextOfKinRelationships = async ({ queryKey }: any) => {
  const { data } = await getData(`${ajaxEndpoints.NEXT_OF_KIN_RELATIONSHIPS}`);
  return data;
};

export default function useNextOfKinRelationships() {
  return useQuery(["getNextOfKinRelationships"], getNextOfKinRelationships, {
    refetchOnWindowFocus: false,
  });
}
