import React, { useEffect, useState } from "react";
import {
  getDataWithDotNet,
  postDataWithDotNet,
} from "../../../newApis/dotNetApiMethods";
import {
  Modal,
  Spin,
  Descriptions,
  Card,
  Button,
  Form,
  Input,
  Image,
  Divider,
} from "antd";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import { ADVANCLY_STORAGE_URL } from "../../../appConstants";
import { Loader } from "../../../UI/Loaders/Loaders";

interface OnboardingRequest {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  gender: string;
  bankId: number;
  bankCode: string | null;
  bankAccountNumber: string;
  accountName: string;
  reference: string;
  address: string;
  postalCode: string;
  city: string;
  state: string | null;
  phoneNoCountryDialCode: string | null;
  dateOfBirth: string | null;
  educationalQualification: string | null;
  effectiveDate: string;
  nextOfKinFirstName: string | null;
  nextOfKinLastName: string | null;
  nextOfKinEmail: string | null;
  nextOfKinPhone: string | null;
  nextOfKinGender: string | null;
  nextOfKinDialCode: string | null;
  nextOfKinStreet: string | null;
  nextOfKinCity: string | null;
  nextOfKinState: string | null;
  nextOfKinCountry: string | null;
  companyName: string | null;
  businessRegistrationNumber: string | null;
  businessRegistrationDate: string | null;
  businessName: string | null;
  userPosition: string | null;
  maritalStatus: string | null;
  onboardingType: string | number;
  customerDocuments: {
    type: string;
    uploadPath: string;
  }[];
  identityNumber: string | null;
}

interface ActionFormData {
  reference: string;
  comment: string;
}

const OnboardingRequests = () => {
  const [requests, setRequests] = useState<OnboardingRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequest, setSelectedRequest] =
    useState<OnboardingRequest | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showActionForm, setShowActionForm] = useState(false);
  const [actionType, setActionType] = useState<"approve" | "reject" | null>(
    null,
  );
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      setLoading(true);
      const response = await getDataWithDotNet(
        "/account/pending_funder_approval",
      );
      if (response.status) {
        setRequests(response.data);
      }
    } catch (error) {
      console.error("Error fetching requests:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to fetch onboarding requests",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (request: OnboardingRequest) => {
    setSelectedRequest(request);
    setShowModal(true);
  };

  const handleActionClick = (type: "approve" | "reject") => {
    setActionType(type);
    setShowModal(false);
    setShowActionForm(true);
    form.setFieldsValue({
      reference: selectedRequest?.reference,
      comment: "",
    });
  };

  const handleActionSubmit = async (values: ActionFormData) => {
    try {
      setSubmitting(true);
      const endpoint =
        actionType === "approve"
          ? "/customers/onboard/approve_individual_offline"
          : "/customers/onboard/reject_individual_offline";

      const response = await postDataWithDotNet(endpoint, { ...values });

      if (response.status) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Request ${actionType === "approve" ? "approved" : "rejected"} successfully`,
        });
        setShowActionForm(false);
        form.resetFields();
        fetchRequests();
      } else {
        throw new Error(response.message || "Operation failed");
      }
    } catch (error) {
      console.error(`Error ${actionType}ing request:`, error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          errorHandler(error) ||
          `Failed to ${actionType} request. Please try again.`,
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Loader centered={true} text="Loading pending onboarding requests..." />
    );
  }

  return (
    <div className="datatable-wrapper">
      <div className="mb-4">You have {requests.length} pending requests</div>

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Country</th>
              <th>Onboarding Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {requests.map((request, index) => (
              <tr key={request.reference}>
                <td>{index + 1}</td>
                <td>{`${request.firstName} ${request.lastName}`}</td>
                <td>{request.email}</td>
                <td>{request.phoneNumber}</td>
                <td>{request.countryCode}</td>
                <td>
                  {request.onboardingType === 0
                    ? "--"
                    : request?.onboardingType}
                </td>
                <td>
                  <Button
                    onClick={() => handleViewDetails(request)}
                    type="dashed"
                  >
                    View Details
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* User Details Modal */}
      <Modal
        title={<h2 className="text-2xl font-semibold">User Details</h2>}
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={900}
        bodyStyle={{ maxHeight: "80vh", overflow: "auto" }}
      >
        {selectedRequest && (
          <div className="space-y-6 p-4">
            {/* Personal Information Card */}
            <Card title="Personal Information" className="shadow-sm">
              <Descriptions column={2}>
                <Descriptions.Item label="First Name">
                  {selectedRequest.firstName}
                </Descriptions.Item>
                <Descriptions.Item label="Last Name">
                  {selectedRequest.lastName}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {selectedRequest.email}
                </Descriptions.Item>
                <Descriptions.Item label="Phone">
                  {selectedRequest.phoneNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Gender">
                  {selectedRequest.gender}
                </Descriptions.Item>
                <Descriptions.Item label="Country">
                  {selectedRequest.countryCode}
                </Descriptions.Item>
                <Descriptions.Item label="Date of Birth">
                  {selectedRequest.dateOfBirth || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Marital Status">
                  {selectedRequest.maritalStatus || "Not provided"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <br />
            {/* Address Information Card */}
            <Card title="Address Information" className="shadow-sm">
              <Descriptions column={2}>
                <Descriptions.Item label="Address">
                  {selectedRequest.address || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="City">
                  {selectedRequest.city || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="State">
                  {selectedRequest.state || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Postal Code">
                  {selectedRequest.postalCode || "Not provided"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <br />
            {/* Bank Details Card */}
            <Card title="Bank Information" className="shadow-sm">
              <Descriptions column={2}>
                <Descriptions.Item label="Bank ID">
                  {selectedRequest.bankId}
                </Descriptions.Item>
                <Descriptions.Item label="Bank Code">
                  {selectedRequest.bankCode || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Account Number">
                  {selectedRequest.bankAccountNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Account Name">
                  {selectedRequest.accountName || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    selectedRequest?.countryCode === "NG"
                      ? "BVN"
                      : "Identity Number"
                  }
                >
                  {selectedRequest.identityNumber || "Not provided"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <br />
            {/* Next of Kin Information Card */}
            <Card title="Next of Kin Information" className="shadow-sm">
              <Descriptions column={2}>
                <Descriptions.Item label="First Name">
                  {selectedRequest.nextOfKinFirstName || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Last Name">
                  {selectedRequest.nextOfKinLastName || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {selectedRequest.nextOfKinEmail || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Phone">
                  {selectedRequest.nextOfKinPhone || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Gender">
                  {selectedRequest.nextOfKinGender || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="City">
                  {selectedRequest.nextOfKinCity || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="State">
                  {selectedRequest.nextOfKinState || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Country">
                  {selectedRequest.nextOfKinCountry || "Not provided"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <br />
            {/* Business Information Card */}
            <Card title="Business Information" className="shadow-sm">
              <Descriptions column={2}>
                <Descriptions.Item label="Company Name">
                  {selectedRequest.companyName || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Business Name">
                  {selectedRequest.businessName || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Registration Number">
                  {selectedRequest.businessRegistrationNumber || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Registration Date">
                  {selectedRequest.businessRegistrationDate || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Position">
                  {selectedRequest.userPosition || "Not provided"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <br />
            {/* Additional Information Card */}
            <Card title="Additional Information" className="shadow-sm">
              <Descriptions column={2}>
                <Descriptions.Item label="Educational Qualification">
                  {selectedRequest.educationalQualification || "Not provided"}
                </Descriptions.Item>
                <Descriptions.Item label="Reference">
                  {selectedRequest.reference}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <br />
            <Divider />
            <h4 className="mb-3">Customer Document</h4>
            <Image
              src={`${ADVANCLY_STORAGE_URL}${selectedRequest?.customerDocuments[0]?.uploadPath}`}
              width={150}
              height={150}
              style={{ objectFit: "cover", marginBottom: "20px" }}
            />
            <br />
            <Divider />
            {/* Action Buttons */}
            <div className="flex justify-end space-x-4 mt-6">
              <Button
                type="default"
                size="large"
                onClick={() => handleActionClick("reject")}
                className="mr-2"
              >
                Reject
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={() => handleActionClick("approve")}
              >
                Approve
              </Button>
            </div>
          </div>
        )}
      </Modal>

      {/* Action Form Modal */}
      <Modal
        title={
          <h2 className="text-xl font-semibold">
            {actionType === "approve" ? "Approve" : "Reject"} Request
          </h2>
        }
        open={showActionForm}
        onCancel={() => {
          setShowActionForm(false);
          form.resetFields();
        }}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleActionSubmit}>
          <Form.Item label="Reference" name="reference">
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Comment"
            name="comment"
            rules={[{ required: true, message: "Please enter a comment" }]}
          >
            <Input.TextArea
              rows={4}
              placeholder={`Enter reason for ${actionType === "approve" ? "approval" : "rejection"}...`}
            />
          </Form.Item>

          <div className="flex justify-end space-x-4">
            <Button
              onClick={() => {
                setShowActionForm(false);
                form.resetFields();
              }}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={submitting}>
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default OnboardingRequests;
