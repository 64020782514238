import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getMaritalStatuses = async () => {
  const { data } = await getData(`${ajaxEndpoints.MARITAL_STATUS}`);
  return data;
};

export default function useMaritalStatuses() {
  return useQuery(["getMaritalStatuses"], getMaritalStatuses, {
    refetchOnWindowFocus: false, // Refetch on window focus
    keepPreviousData: true,
  });
}
