import React from "react";

const EquityContribution = ({ dataRecord }: any) => {
  const {
    require_equity_contribution,
    equity_contribution_amount,
    total_cost_amount,
    cost_discount_percentage,
    equity_contribution_percentage,
    description,
  } = dataRecord;
  return (
    <div>
      <React.Fragment>
        <div className="row">
          <div className="col-xs-6">
            <label className="label-txt">
              Is Equity contribution Required{" "}
            </label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="user__form form-control"
                readOnly
                value={require_equity_contribution}
              />
              <i className="fas fa-money-bill-wave" />
            </div>
          </div>

          {require_equity_contribution && (
            <div className="col-xs-6">
              <label className="label-txt">Item Amount</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={total_cost_amount ? total_cost_amount : "Unavailable"}
                />
                <i className="fas fa-money-bill-wave" />
              </div>
            </div>
          )}
        </div>

        {require_equity_contribution && (
          <div className="row">
            <div className="col-xs-6">
              <label className="label-txt">Equity Percentage</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  readOnly
                  className="user__form form-control"
                  value={
                    equity_contribution_percentage
                      ? equity_contribution_percentage
                      : "Unavailable"
                  }
                />
                <i className="fas fa-percentage" />
              </div>
            </div>
            <div className="col-xs-6">
              <label className="label-txt">Equity Amount</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  readOnly
                  className="user__form form-control"
                  value={
                    equity_contribution_amount
                      ? equity_contribution_amount
                      : "Unavailable"
                  }
                />
                <i className="fas fa-coins" />
              </div>
            </div>
          </div>
        )}

        {require_equity_contribution && (
          <div className="row">
            <div className="col-xs-6">
              <label className="label-txt">Discount Applied Percentage</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={
                    cost_discount_percentage
                      ? cost_discount_percentage
                      : "Unavailable"
                  }
                />
                <i className="fas fa-tag" />
              </div>
            </div>
            <div className="col-xs-6">
              <label className="label-txt">Item Description</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={description ? description : "Unavailable"}
                />
                <i className="fas fa-tag" />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    </div>
  );
};

export default EquityContribution;
