import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getWorldCountries = async (sourceTypeId: number) => {
  const { data } = await getData(
    `${ajaxEndpoints.WORLD_COUNTRY_CODES}?sourceTypeId=${sourceTypeId}`,
  );
  return data;
};

export default function useWorldCountries(sourceTypeId: number) {
  return useQuery(
    ["getWorldCountries", sourceTypeId],
    () => getWorldCountries(sourceTypeId),
    {
      refetchOnWindowFocus: true,
    },
  );
}
