import { useState } from "react";

import { useDispatch } from "react-redux";
import { showModal } from "../../redux/actions/modal";
import { getData } from "../../newApis/apiMethods";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { appInsights } from "../../config/appInsights";
import { getMimeType, splitAtLastOccurrenceOfItem } from "./helper";
import {
  clearDocumentViewModalRecord,
  updateDocumentViewModalRecord,
} from "../../redux/actions/documenViewer";

const useDocumentViewer = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const prepareDownloadLink = (content: string, mimeType: string) => {
    const linkSource = `data:${mimeType};base64,${content}`;

    dispatch(
      updateDocumentViewModalRecord({
        base64Url: linkSource,
        mimeType: mimeType,
      })
    );
  };

  const renderWithFilePath = async (filePath: string, mimeType: string) => {
    try {
      dispatch(clearDocumentViewModalRecord());

      dispatch(
        showModal({
          modalIdentifier: "documentviewer",
          dataRecord: { documentUrl: "", type: "", isLoading: true },
          action: "customrange",
          type: "alert",
        })
      );
      const data = await getData(
        `${ajaxEndpoints.DOCUMENT_URL}?file_path=${filePath}`

        // `${ajaxEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${filePath}`
      );
      if (data?.data?.file_url) {
        downloadFileAndConvertToBase64(
          data?.data?.file_url,
          data?.data?.file_name,
          mimeType
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CustomViewDocumentHandler.ts",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: `Unable to view document: ${error?.response?.data?.message}`,
          },
        })
      );
    }
    setIsLoading(false);
  };

  const downloadFileAndConvertToBase64 = async (
    currentUrl: string,
    currentFileName: string,
    mimeType: string
  ) => {
    setIsLoading(true);
    try {
      const decodedUrl = decodeURIComponent(currentUrl);

      // Fetch the image file from the URL
      const response = await fetch(decodedUrl);
      if (!response.ok) {
        throw new Error("File download failed");
      }

      // Convert the response to a Blob
      const blob = await response.blob();

      // Convert the Blob to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        // Remove the 'data:[mime-type];base64,' prefix
        //@ts-ignore
        const base64Content = base64String.split(",")[1];

        if (base64Content.length > 0) {
          prepareDownloadLink(base64Content, mimeType);
        }

        setIsLoading(false);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  const renderCustomerOfferWithFilePath = async (
    filePath: string,
    mimeType: string,
    id: number,
    loan_application_id: number,
    customer_id: number,
    offer_letter_detail_id: number
  ) => {
    try {
      dispatch(clearDocumentViewModalRecord());

      dispatch(
        showModal({
          modalIdentifier: "customerofferslaviewer",
          dataRecord: {
            documentUrl: "",
            type: "",
            isLoading: true,
            id,
            loan_application_id,
            customer_id,
            offer_letter_detail_id,
          },
          action: "customrange",
          type: "alert",
        })
      );
      const data = await getData(
        `${ajaxEndpoints.DOCUMENT_URL}?file_path=${filePath}`

        // `${ajaxEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${filePath}`
      );
      if (data?.data?.file_url) {
        downloadFileAndConvertToBase64(
          data?.data?.file_url,
          data?.data?.file_name,
          mimeType
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CustomViewDocumentHandler.ts",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: `Unable to view document: ${error?.response?.data?.message}`,
          },
        })
      );
    }
    setIsLoading(false);
  };

  const handleViewDocument = (isFullUrl: boolean, url: string) => {
    const result = splitAtLastOccurrenceOfItem(url, ".");
    const mimeType = getMimeType(result);

    if (!mimeType) {
      return dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: `Unable to render documents ending with .${
              url.split(".")[1]
            }`,
          },
        })
      );
    }

    setIsLoading(true);
    if (isFullUrl) {
      setIsLoading(false);
      return dispatch(
        showModal({
          modalIdentifier: "documentviewer",
          dataRecord: { documentUrl: url, mimeType },
          action: "customrange",
          type: "alert",
        })
      );
    }

    return renderWithFilePath(url, mimeType);
  };

  const handleViewCustomerOfferLetterSla = (
    isFullUrl: boolean,
    url: string,
    id: number,
    loan_application_id: number,
    customer_id: number,
    offer_letter_detail_id: number,
    comment?: string
  ) => {
    const result = splitAtLastOccurrenceOfItem(url, ".");
    const mimeType = getMimeType(result);

    if (!mimeType) {
      return dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: `Unable to render documents ending with .${
              url.split(".")[1]
            }`,
          },
        })
      );
    }

    setIsLoading(true);
    if (isFullUrl) {
      setIsLoading(false);
      return dispatch(
        showModal({
          modalIdentifier: "customerofferslaviewer",
          dataRecord: { documentUrl: url, mimeType },
          action: "customrange",
          type: "alert",
        })
      );
    }

    return renderCustomerOfferWithFilePath(
      url,
      mimeType,
      id,
      loan_application_id,
      customer_id,
      offer_letter_detail_id
    );
  };

  return { handleViewDocument, isLoading, handleViewCustomerOfferLetterSla };
};

export default useDocumentViewer;
